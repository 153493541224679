<template>
    <b-modal :id="id" hide-footer hide-header size="lg" centered @shown="onModalShown" @hide="closeOnboarding">
        <div class="my-3 px-4">
            <h2 v-if="isPurchase" class="font-weight-bold text-center purchase-title">
                Success! Your Purchase is Complete.
            </h2>
            <h5 class="font-weight-bold text-center">A special gift for you:</h5>

            <div class="text-center mt-3">
                <div class="d-flex justify-content-center f-24">You've unlocked one free month of Premium — on us!</div>
                <div class="position-relative">
                    <IconUnlocked class="unlocked-icon" />
                </div>
                <div class="position-relative">
                    <PremiumMarker :show="true" :popover="false" class="premium-marker" />
                </div>
                <div class="d-flex flex-wrap justify-content-between align-items-baseline brands">
                    <div
                        v-for="item in brands"
                        :key="item.name"
                        class="mb-3 d-flex flex-column justify-content-center align-items-center brand pointer"
                        @click="openInNewTab(brightsproutUrls[item.brand])"
                    >
                        <img :key="item.image" :src="item.image" class="brand-image" />
                        <p class="brand-name mt-2 mb-0 text-uppercase text-primary" v-html="item.name"></p>
                    </div>
                </div>
                <div class="mt-2 text-center">
                    <h5 class="font-weight-bold mb-3 mt-3">Make unlimited activities with all features for 30 days.🎉</h5>

                    <p class="f-20">After 30 days, your account will revert to free features only.</p>
                </div>
            </div>
            <div class="actions d-flex justify-content-center align-items-center">
                <button
                    class="btn"
                    :class="onPublishing ? 'btn-secondary mr-2' : 'btn-primary'"
                    @click="handleContinueEditingButtonClick()"
                >
                    Continue Editing
                </button>
                <button
                    v-if="onPublishing"
                    id="onboarding-complete-publish-button"
                    class="btn btn-success ml-4"
                    @click="handlePublishButtonClick()"
                >
                    Finish + Print
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { dangerToast } from '../helpers/toasts'
import { brightsproutUrls } from '../config/Config'
import UserEvent from '../objects/UserEvent'

export default defineComponent({
    name: 'OnboardingComplete',
    components: {
        IconUnlocked: () => import('../stories/components/icons/IconUnlocked.vue'),
        PremiumMarker: () => import('../widgets/premium-marker.vue'),
    },
    data() {
        return {
            id: 'onboarding-complete',
            brands: [
                {
                    name: 'Premium<br>Worksheets',
                    image: '/images/worksheet.webp',
                    brand: 'worksheets',
                },
                {
                    name: 'Crosswords',
                    image: '/images/crossword.webp',
                    brand: 'crosswords',
                },
                {
                    name: 'Word Searches',
                    image: '/images/wordsearch.webp',
                    brand: 'wordsearches',
                },
                {
                    name: 'Bingo',
                    image: '/images/bingo.webp',
                    brand: 'bingo',
                },
                {
                    name: 'Flashcards',
                    image: '/images/flashcard.webp',
                    brand: 'flashcards',
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            hasPlan: 'user/hasPlan',
            hasPlans: 'subscription/hasPlans',
            onPublishing: 'document/onpublishing',
            isPurchase: 'subscription/isPurchase',
        }),
        hasPlanOrPlans() {
            return this.hasPlan || this.hasPlans
        },
        brightsproutUrls() {
            return brightsproutUrls
        },
        onboardingType() {
            return this.isPurchase ? 'single purchase' : 'cardless free trial'
        },
    },
    methods: {
        async handlePublishButtonClick() {
            await this.$logTrackingEvent(UserEvent.ONBOARDING_COMPLETE_PUBLISH_BUTTON_CLICKED, this.onboardingType)

            if (!this.hasPlanOrPlans) {
                return
            }

            this.$bvModal.hide(this.id)
            this.$bvModal.show('printable-modal')
        },
        handleContinueEditingButtonClick() {
            this.$bvModal.hide(this.id)
        },
        async onModalShown() {
            await this.$logTrackingEvent(UserEvent.ONBOARDING_COMPLETE_OVERLAY_SEEN, this.onboardingType)
            await this.recheckSubscription()
        },
        async recheckSubscription() {
            try {
                await this.$store.dispatch('subscription/getSubscription')
                if (!this.hasPlanOrPlans) {
                    throw new Error('No subscription found')
                }
            } catch (error) {
                this.$bvToast.toast(
                    'There might be a problem with your subscripton. Please try to refresh page if you notice any errors.',
                    dangerToast,
                )
            }
        },
        openInNewTab(url) {
            window.open(url, '_blank')
        },
        async closeOnboarding() {
            await this.$store.dispatch('subscription/setValue', { is_purchase: false })
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base-worksheets.scss';

.btn {
    min-width: 9em;
    height: 3rem;
    font-size: 1.125rem;
}

@media (min-width: 991px) {
    .btn {
        min-width: 12em;
    }
}

.f-24 {
    font-size: 1.5rem;
}

.f-20 {
    font-size: 1.25rem;
}

.actions {
    margin-top: 2.5rem;
}

.brands {
    border: 0.4375rem solid rgba($primary-100, 0.5);
    border-radius: 0.625rem;
    padding: 1.875rem 1rem 1rem 1rem;
    margin-top: 2.5rem;

    .brand {
        .brand-image {
            border: 1px solid #000000;
            width: 7.5rem;
            height: 7.5rem;
        }

        .brand-name {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1;
        }
    }
}

.unlocked-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 1.875rem;
    z-index: 1;
    border: 0.25rem solid #ffffff;
    width: 3rem;
    height: 3.75rem;
}

.premium-marker {
    position: absolute;
    left: 7.7rem;
    transform: translate(-50%, -50%);
    top: 5.6rem;
    z-index: 1;
    font-size: 1.25rem;
}

.purchase-title {
    margin-bottom: 2.5rem;
}
</style>
